
import {computed, defineComponent, onMounted, ref} from "vue";
import { config } from "@/core/helpers/config";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "Builder",
  props: {
    profile: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const i18n = useI18n();
    const store = useStore();
    const tabIndex = ref(0);
    const isFirst = ref(true);
    const language = ref("es");
    const items_per_page = ref(10);
    const number_windows = ref(10);
    const number_activities_logs = ref(10);
    const view_type = ref("card");
    const notifications = ref(0);
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);

    const options = [
      {
        value: "en",
        flag: "media/flags/united-states.svg",
        name: "en",
      },
      {
        value: "es",
        flag: "media/flags/spain.svg",
        name: "es",
      },
    ];
    const items_options = [10, 25, 50, 100];
    const windows_options = [10, 7, 5, 3, 1];
    const activities_logs_options = [10, 7, 5, 3, 1];
    const view_options = ["card", "table"];
    const notification_options = [
      {
        name: "iexacttime",
        value: 0,
      },
      {
        name: "ithirtyminutesbefore",
        value: 30,
      },
      {
        name: "ihourbefore",
        value: 60,
      },
      {
        name: "idaybefore",
        value: 1440,
      },
    ];

    const locale = computed(() => i18n.locale.value);

    onMounted(() => {
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      language.value = localStorage.getItem("lang") as any;
      const preferences = JSON.parse(
        localStorage.getItem("preferences") as any
      );
      items_per_page.value = preferences.items_per_page
        ? preferences.items_per_page
        : 10;
      view_type.value = preferences.view_type_items
        ? preferences.view_type_items
        : "card";
      notifications.value = preferences.reminder;
      number_windows.value = preferences.number_windows
        ? preferences.number_windows
        : 10;
      number_activities_logs.value = preferences.number_activities_log
        ? preferences.number_activities_log
        : 10;
    });

    /**
     * Reset config
     * @param event
     */
    const reset = (event) => {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    /**
     * Submit form
     * @param event
     */
    const submit = (event) => {
      store.commit("setLoadingStatus", true);
      event.preventDefault();
      store.commit("setsaveForm", false);
      store.commit("setLoading", false);
      ApiService.post(`/api/users/${user.id}/store-preferences`, {
        language: language.value,
        items_per_page: items_per_page.value,
        view_type_items: view_type.value,
        reminder: notifications.value,
        number_windows: number_windows.value,
        number_activities_log: number_activities_logs.value,
        lang: locale.value,
      }).then(({ data }) => {
        const preferences = {
          items_per_page: items_per_page.value,
          view_type_items: view_type.value,
          reminder: notifications.value,
          number_windows: number_windows.value,
          number_activities_log: number_activities_logs.value,
        };
        localStorage.setItem("lang", language.value);
        localStorage.setItem("preferences", JSON.stringify(preferences));
        store.dispatch("GET_LOGACTIVITIES_API");
        store.commit("setLoadingStatus", false);
      });
    };

    const changeItemperPage = () => {
      // console.log("changeItemperPage");
      // store.commit("setsaveForm", true);
    };
    const changeLanguaje = () => {
      // store.commit("setsaveForm", true);
    };
    const changeView = () => {
      // store.commit("setsaveForm", true);
    };
    const changeNotification = () => {
      // store.commit("setsaveForm", true);
    };
    const changeWindows = () => {
      // console.log("changeWindows");
      // store.commit("setsaveForm", true);
    };
    const changeActivitiesLogs = () => {
      // console.log("changeActivitiesLogs");
      // store.commit("setsaveForm", true);
    };
    return {
      tabIndex,
      config,
      language,
      view_type,
      items_per_page,
      items_options,
      view_options,
      options,
      notifications,
      notification_options,
      windows_options,
      activities_logs_options,
      number_windows,
      number_activities_logs,
      reset,
      setActiveTab,
      submit,
      changeItemperPage,
      changeLanguaje,
      changeView,
      changeNotification,
      changeWindows,
      changeActivitiesLogs,
    };
  },
});
